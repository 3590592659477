export default {
  404: {
    title: '404 Page not found',
    text: 'The page you are looking for is not available.<br /> Try going back to the homepage or try again later.',
  },
  500: {
    title: '500 Internal Server Error',
    text: 'Sorry, an internal error has occurred. <br />We are working to fix the issue as soon as possible. <br /> Try going back to the homepage or try again later.',
  },
}
