export default {
  back: 'Back',
  search: 'Search',
  searchPlaceholder: 'Find Something',
  products: 'Products',
  projects: 'Projects',
  bespoke: 'Bespoke',
  designers: 'Designers',
  addresses: 'Addresses',
  requests: 'Requests',
  distributors: 'Distributors',
  news: 'News',
  company: 'Company',
  research: 'R&D',
  contacts: 'Contacts',
  support: 'Support',
  catalogues: 'Catalogues',
  certifications: 'Certifications',
  collections: 'Collections',
  systems: 'Systems',
  finishes: 'Finishes',
  market: 'Market/Lang',
  policy: 'Privacy Policy',
  cookie: 'Cookie Policy',
  newsletter: 'Newsletter',
  facebook: 'Facebook',
  pinterest: 'Pinterest',
  instagram: 'Instagram',
  'all-collections': 'All collections',
  'all-finishes': 'All Zava fineshes',
  'view-all': 'View all',
  'banner-company': 'Our company',
  'banner-bespoke': 'Discover bespoke',
  'catalogues-footer': 'Download our catalogues',
  'newsletter-footer': 'Subscribe to the newsletter',
  'distributor-footer': 'Find a dealer',
}
