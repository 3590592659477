export default {
  backLinkCollection: 'Tutte le collezioni',
  backLinkSystem: 'Tutti i sistemi',
  backLink: 'Torna alla collezione {collection} ',
  backLinkCategory: 'Torna alla categoria',
  backLinkFinish: 'Torna alla finitura',
  downloadTitle: 'Area Download',
  noDownloadTitle: 'Nessun file del prodotto disponibile',
  specTitle: 'Specifiche tecniche',
  specRelatedCategories: 'Categoria',
  specProductMaterial: 'Materiale',
  specProductCertifications: 'Certificazioni',
  specProductDimerable: 'Dimmerazione',
  specDesigner: 'Designer',
  specProductSizes: 'Dimensioni',
  specProductCeilingSize: 'Dimensioni rosone',
  specProductLightSource: 'Sorgente luminosa',
  finishingsTitle: 'Finiture disponibili',
  finishingsProductsTitle: 'Prodotti con finitura {finishing}',
  designersProductsTitle: 'Progetti di {designer}',
  finishingsExternalTitle: 'Finiture',
  finishingsInternalTitle: 'Finiture Interne',
  finishingsWireTitle: 'Finiture Cavo',
  finishingsBaseTitle: 'Finiture Base',
  siblingsTitle: 'Altre versioni {name}',
  recommendedTitle: 'Potrebbe piacerti',
  productDownloadFile3d: 'File 3D',
  productDownloadDisegnoTecnico: 'Disegno tecnico',
  productDownloadSchedaTecnica: 'Scheda Tecnica',
  productDownloadIstruzioniDiMontaggio: 'Istruzioni di montaggio',
  productDownloadFotometria: 'Fotometria',
}
