export default {
  title: 'Contacts',
  'title-requests': 'Send a Request',
  'title-requests-sent': 'Request sent',
  'text-requests-sent':
    'Thank you for contacting us, We will respond as soon as possible.',
  'title-requests-fail': 'Request not sent',
  'text-requests-fail': 'There was a problem sending the request.',
  'title-distributorsitalia': 'Distributors in Italy',
  'title-distributorseuropa': 'Distributors in Europe',
  'title-distributorsworld': 'Distributors worldwide',
  'physical-appointment':
    'Do you want to schedule an appointment at our showroom?',
  'digital-appointment': 'Would you prefer a virtual appointment?',
}
