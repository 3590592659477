export default {
  backLinkDesigners: 'All designers',
  backLinkFinishes: 'All finishes',
  searchFail: 'Your search did not yield any results.',
  preTitleCatalogue: 'Download the catalog in PDF format',
  privacyPolicy: 'Privacy Policy',
  descriptionPrivacyPolicy:
    'Read our Privacy Policy to understand how we collect, use, and protect your personal information at Zava.',
  cookiePolicy: 'Cookie Policy',
  descriptionCookiePolicy:
    'Find out how Zava uses cookies to enhance your online experience. Read our Cookie Policy for more information.',
  seoFamilyDescriptionPlaceholder: `{ collection } Zava collection`,
  seoProductDescriptionPlaceholder: `{ product } Zava product`,
  seoFinishesDescriptionPlaceholder: `{ finishing } Zava finishing`,
  seoDesignerDescriptionPlaceholder: `{ designer } Zava designer`,
  market: {
    it: 'Italia',
    en: 'Global',
    'en-us': 'Usa',
  },
  seoTitleCollection: 'Collection',
  seoTitleProduct: 'Product',
  seoDescriptionDiscover: 'Discover',
}
