import home from './home.js'
import news from './news.js'
import projects from './projects.js'
import product from './product.js'
import contacts from './contacts.js'

export default {
  home,
  news,
  projects,
  product,
  contacts,
}
