export default {
  apiContactFormPostinId: 'cf97b9aa-9b2f-4ae2-bfdd-9f39d9eb859a',

  optional: 'optional',

  fullNameLabel: 'Full Name',
  fullName: 'Enter your full name',

  emailLabel: 'Email',
  email: 'Your email address',

  requestTypeLabel: 'Select Request Type',
  requestType: 'Type of Request',
  requestTypes:
    'Request an Appointment in Showroom, Book a Virtual Appointment, Commercial Information Request, Technical Support and Assistance, Product Information Request, Feedback or Complaints',

  countryLabel: 'Country',
  country: 'Select your country',

  nameLabel: 'First Name',
  name: 'Enter your first name',

  surnameLabel: 'Surname',
  surname: 'Enter your surname',

  companyLabel: 'Company',
  company: 'Your company name',

  phoneLabel: 'Phone',
  phone: 'Phone number',

  messageLabel: 'Message',
  message: 'Write your message here',

  roleLabel: 'Role',
  role: 'Select your role',
  roles: 'Professional, Private Individual, Press, Retailer',

  termsLabel:
    'I agree to the <a href="{ url }" target="_blank" rel="noreferrer" class="link-underline-1"><span>privacy policy</span></a>',
  termsExtLabel:
    'Pursuant to and for the purposes of Articles 7, 12, 13 et seq. of EU Reg. 2016/679 - GDPR: I consent to the processing of my personal data as per the <a href="{ url }" target="_blank" rel="noreferrer" class="link-underline-1"><span>privacy policy</span></a> and to receive the information requested through this form by email or phone.',
  termsError: 'You must accept our privacy policy.',
}
