export default {
  backLinkCollection: 'All collections',
  backLinkSystem: 'All systems',
  backLink: 'Back to {collection} collection',
  backLinkCategory: 'Back to category',
  backLinkFinish: 'Back to finishing',
  downloadTitle: 'Download Area',
  noDownloadTitle: 'No product files available',
  specTitle: 'Technical Specifications',
  specRelatedCategories: 'Category',
  specProductMaterial: 'Material',
  specProductCertifications: 'Certifications',
  specProductDimerable: 'Dimming',
  specDesigner: 'Designer',
  specProductSizes: 'Dimensions',
  specProductCeilingSize: 'Canopy Dimensions',
  specProductLightSource: 'Light Source',
  finishingsTitle: 'Available finishes',
  finishingsProductsTitle: 'Products featuring {finishing}',
  designersProductsTitle: 'Projects of {designer}',
  finishingsExternalTitle: 'Finishes',
  finishingsInternalTitle: 'Internal Finishes',
  finishingsWireTitle: 'Cable Finishes',
  finishingsBaseTitle: 'Base Finishes',
  siblingsTitle: 'Other versions of {name}',
  recommendedTitle: 'You might like',
  productDownloadFile3d: 'File 3D',
  productDownloadDisegnoTecnico: 'Technical Drawing',
  productDownloadSchedaTecnica: 'Technical Sheet',
  productDownloadIstruzioniDiMontaggio: 'Assembly Instructions',
  productDownloadFotometria: 'Photometry',
}
