export default {
  novita: 'Novità',
  verniciatura: 'Verniciature',
  inFoglia: 'In foglia',
  speciale: 'Speciali',
  ottoni: 'Ottoni',
  marmi: 'Marmo',
  cavo: 'Cavo',
  allNews: 'Tutti gli articoli',
  prodotti: 'Prodotti',
  eventi: 'Eventi',
  innovazioni: 'Innovazioni',
  premi: 'Premi',
  press: 'Press',
  allProjects: 'Tutti i progetti',
  italia: 'Italia',
  europa: 'Europa',
  mondo: 'Mondo',
  extraue: 'Extra Ue',
}
