export default {
  back: 'Indietro',
  search: 'Cerca',
  searchPlaceholder: 'Cerca',
  products: 'Prodotti',
  projects: 'Progetti',
  bespoke: 'Bespoke',
  designers: 'Designers',
  addresses: 'Indirizzi',
  requests: 'Richieste',
  distributors: 'Distributori',
  news: 'News',
  company: 'Azienda',
  research: 'R&D',
  contacts: 'Contatti',
  support: 'Assistenza',
  catalogues: 'Cataloghi',
  certifications: 'Certificazioni',
  collections: 'Collezioni',
  systems: 'Sistemi',
  finishes: 'Finiture',
  market: 'Mercato/Lingua',
  policy: 'Privacy Policy',
  cookie: 'Cookie Policy',
  newsletter: 'Newsletter',
  facebook: 'Facebook',
  pinterest: 'Pinterest',
  instagram: 'Instagram',
  'all-collections': 'Tutte le collezioni',
  'all-finishes': 'Tutte le finiture Zava',
  'view-all': 'Vedi tutti',
  'banner-company': 'La nostra azienda',
  'banner-bespoke': 'Scopri bespoke',
  'catalogues-footer': 'Scarica i nostri cataloghi',
  'newsletter-footer': 'Iscriviti alla newsletter',
  'distributor-footer': 'Trova un Rivenditore',
}
