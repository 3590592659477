import navigation from './navigation.js'
import cta from './cta.js'
import footer from './footer.js'
import bucket from './bucket.js'
import cursor from './cursor.js'
import listing from './listing.js'
import filters from './filters.js'
import form from './form.js'
import errors from './errors.js'
import pages from './pages/index.js'

export default {
  navigation,
  cta,
  footer,
  bucket,
  cursor,
  listing,
  filters,
  form,
  errors,
  pages,
}
