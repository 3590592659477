export default {
  title: 'Contatti',
  'title-requests': 'Invia una richiesta',
  'title-requests-sent': 'Richiesta inviata',
  'text-requests-sent':
    'Grazie per averci contattato, vi risponderemo nel minor tempo possibile.',
  'title-requests-fail': 'Richiesta non inviata',
  'text-requests-fail':
    'Si è presentato un problema durante l’invio della richiesta.',
  'title-distributorsitalia': 'Distributori in Italia',
  'title-distributorseuropa': 'Distributori in Europa',
  'title-distributorsworld': 'Distributori nel mondo',
  'physical-appointment':
    'Vuoi fissare un appuntamento all’interno del nostro showroom?',
  'digital-appointment': 'Preferisci un appuntamento virtuale?',
}
