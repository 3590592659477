export default {
  apiContactFormPostinId: 'aa5c411f-ab1e-42ef-bfe1-9e7da51dea29',

  optional: 'opzionale',

  fullNameLabel: 'Nome e cognome',
  fullName: 'Inserisci il tuo nome completo',

  emailLabel: 'Email',
  email: 'Il tuo indirizzo email',

  requestTypeLabel: 'Seleziona il tipo di richiesta',
  requestType: 'Tipo di richiesta',
  requestTypes:
    'Richiedi Appuntamento in Showroom, Prenota un Appuntamento Virtuale,Richiesta Informazioni Commerciali,Assistenza e Supporto Tecnico,Richiesta Informazioni su Prodotti,Feedback o Reclami',

  countryLabel: 'Nazione',
  country: 'Seleziona la tua nazione',

  nameLabel: 'Nome',
  name: 'Inserisci il tuo nome',

  surnameLabel: 'Cognome',
  surname: 'Inserisci il tuo cognome',

  companyLabel: 'Azienda',
  company: 'Nome della tua azienda',

  phoneLabel: 'Telefono',
  phone: 'Numero di telefono',

  messageLabel: 'Messaggio',
  message: 'Scrivi qui il tuo messaggio',

  roleLabel: 'Ruolo',
  role: 'Seleziona il tuo ruolo',
  roles: 'Professional, Private Individual, Press, Retailer',

  termsLabel:
    'Acconsento all’<a href="{ url }" target="_blank" rel="noreferrer" class="link-underline-1"><span>informativa sulla privacy</span></a>',
  termsExtLabel:
    'Ai sensi e per gli effetti degli artt. 7, 12, 13 ss. del Reg. UE 2016/679 - GDPR: Acconsento al trattamento dei miei dati personali come da <a href="{ url }" target="_blank" rel="noreferrer" class="link-underline-1"><span>informativa sulla privacy</span></a> e a ricevere via email o telefono le informazioni richieste tramite questo modulo',
  termsError: 'È necessario accettare la nostra policy sulla privacy.',
}
