export default {
  novita: 'New Arrivals',
  verniciatura: 'Paintings',
  inFoglia: 'Leaf',
  speciale: 'Specials',
  ottoni: 'Brass',
  marmi: 'Marble',
  cavo: 'Cable',
  allNews: 'General',
  prodotti: 'Products',
  eventi: 'Events',
  innovazioni: 'Innovations',
  premi: 'Awards',
  press: 'Press',
  allProjects: 'All projects',
  italia: 'Italy',
  europa: 'Europe',
  mondo: 'World',
  extraue: 'Extra Ue',
}
